<template>
  <div class="public-body">
    <div class="public-body__block">
      <h1 class="title title--center">Оферта о заключении партнерского соглашения</h1>
      <p class="public-body__paragraph">Текст настоящего документа размещен в сети Интернет по адресу <a
        href="/oferta" target="_blank">https://lk.mkp.ruy.ru/oferta</a> и содержит все существенные условия Партнерского
        соглашения. Настоящий документ является предложением <b>Общероссийской общественной организации «Российский Союз
          Молодежи»</b> (далее – ОРГАНИЗАЦИЯ) заключить Партнерское соглашение с любым заинтересованным лицом.
      </p>
      <p class="public-body__paragraph">Настоящий документ является публичной офертой о заключении Партнерского
        соглашения (далее – Оферта) в соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации.
        Надлежащим акцептом настоящей Оферты в соответствии со статьей 438 Гражданского кодекса Российской Федерации
        считается осуществление следующих действий:
      </p>
      <p class="public-body__paragraph">
      <ul>
        <li>1. Ознакомление с условиями Оферты, Партнерского соглашения и всех приложений к ним.</li>
        <li>2. Регистрация на сайте ОРГАНИЗАЦИИ по адресу <a href="/partner/registration" target="_blank">https://lk.mkp.ruy.ru/partner/registration</a>
          в качестве ПАРТНЁРА.
        </li>
        <li>3. Выражение согласия с условиями Партнерского соглашения путем проставления отметки в поле «Я принимаю
          условия Партнерского соглашения» и последующего нажатия кнопки «Зарегистрироваться» на странице
          <a href="/partner/registration" target="_blank">https://lk.mkp.ruy.ru/partner/registration</a>.
        </li>
      </ul>
      </p>
      <p class="public-body__paragraph">Датой акцепта Оферты (датой заключения Партнерского соглашения) считается дата
        нажатия кнопки
        «Зарегистрироваться» после совершения вышеуказанных действий.
      </p>
      <p class="public-body__paragraph">Если Вы не согласны с условиями Партнерского соглашения и не намерены
        соблюдать его условия, не регистрируйтесь
        в качестве ПАРТНЁРА на сайте <a href="https://mkp.ruy.ru/" target="_blank">https://mkp.ruy.ru/</a>.
      </p>
      <h2 class="title title--center">ПАРТНЕРСКОЕ СОГЛАШЕНИЕ</h2>
      <p class="public-body__paragraph"><b>1. Термины и определения</b></p>
      <p class="public-body__paragraph">Термины и определения, используемые в настоящем Договоре, будут иметь следующее
        значение:
      </p>
      <p class="public-body__paragraph">«ОРГАНИЗАЦИЯ» – Общероссийской общественной организации «Российский Союз
        Молодежи», ОГРН 1027739485620, ИНН 7701047627.
      </p>
      <p class="public-body__paragraph">«ПАРТНЕР» – физическое или юридическое лицо, которое заключило с ОРГАНИЗАЦИЕЙ
        Партнерское соглашение путем акцепта Оферты способом, указанным в Оферте.
      </p>
      <p class="public-body__paragraph">«Пользователь» – клиент ОРГАНИЗАЦИИ, которому предоставлена Карта и владеющий
        Мобильным Терминалом с предустановленным в него Мобильным Приложением (на усмотрение пользователя).
      </p>
      <p class="public-body__paragraph">«Карта» – именная пластиковая (либо виртуальная) карта с уникальным
        идентификационным 12-значным номером и логотипом Европейской ассоциации молодежных карт European Youth Card
        Association (далее – EYCA), выпущенная ОРГАНИЗАЦИЕЙ (либо компаниями, действующими в партнёрстве с ОРГАНИЗАЦИЕЙ)
        в рамках федерального проекта «Международный клуб привилегий Российского Союза Молодежи» (далее – МКП РСМ) и
        дающая её держателю возможность получать скидки при приобретении товаров, работ и услуг у партнёров EYCA и МКП
        РСМ на территории стран – членов EYCA, а также пользоваться иными привилегиями, предоставляемыми РСМ, EYCA и
        партнёрами.
      </p>
      <p class="public-body__paragraph">«Мобильное Приложение» – программа (программное обеспечение), права на которое
        принадлежат ОРГАНИЗАЦИИ, установленное на Мобильном Терминале, и обеспечивающее, в том числе, возможность
        Пользователям формировать заявку на оформление карты, информацию о Карте и ее функциях, а также возможность
        совершения Пользователям действий в целях функционального использования Карты. Мобильное Приложение
        устанавливается в Мобильный Терминал владельцем Мобильного Терминала самостоятельно с помощью сети Интернет.
      </p>
      <p class="public-body__paragraph">«Мобильный Терминал» – мобильный телефон, карманный персональный компьютер,
        планшет или любое иное аналогичное компьютерное устройство, одной из функций которого является обеспечение
        соединений в сетях связи.
      </p>
      <p class="public-body__paragraph">«Оборудование ОРГАНИЗАЦИИ» – программно-аппаратный комплекс, используемый
        ОРГАНИЗАЦИЕЙ для взаимодействия с Оборудованием ПАРТНЕРА в рамках настоящего Договора.
      </p>
      <p class="public-body__paragraph">«Оборудование ПАРТНЕРА» – программно-аппаратный комплекс, используемый ПАРТНЕРОМ
        для оказания Услуг по настоящему Договору.
      </p>
      <p class="public-body__paragraph">«Согласованное Средство Связи» – способ отправки информации (электронная почта,
        факс, обмен информацией между серверами по установленному протоколу и т.п.), позволяющий однозначно установить
        отправителя. Считается, что независимо от конкретного вида используемых средств связи, выбранный способ отправки
        информации позволяет однозначно установить отправителя, если отправляемая информация содержит электронные
        идентификаторы (электронные ключи, коды и т.п.), заблаговременно предоставленные отправителем информации. Обмен
        информации об идентификаторе производится по электронной почте на адреса уполномоченных сотрудников Сторон.
        Стороны вправе установить и признать Согласованным Средством Связи любой способ отправки информации.
      </p>
      <p class="public-body__paragraph">«Личный кабинет ПАРТНЕРА» – совокупность защищенных страниц на сайте ОРГАНИЗАЦИИ
        <a href="/partner" target="_blank">https://lk.mkp.ruy.ru/partner</a>, созданных в результате регистрации
        Партнера, используя которые Партнер имеет возможность загружать информацию о предоставляемых им скидках и иных
        привилегиях Пользователям Карт, а также осуществлять иные действия, предусмотренные явными функциями Личного
        кабинета.
      </p>
      <p class="public-body__paragraph">Идентификационные сведения для доступа в Личных кабинет указываются ПАРТНЕРОМ
        при регистрации.</p>
      <p class="public-body__paragraph"><b>2. Предмет СОГЛАШЕНИЯ</b></p>
      <p class="public-body__paragraph">Настоящее Партнерское соглашение определяет порядок и условия сотрудничества
        Сторон в рамках проекта МКП РСМ по созданию и развитию на территории Российской Федерации системы скидок и иных
        привилегий по дисконтным Картам, а также в целях повышения и поддержания интереса Пользователей к товарам
        (услугам, работам и другим продуктам) ПАРТНЕРА или компаний, входящих в одну группу лиц с ПАРТНЕРОМ.
      </p>
      <p class="public-body__paragraph"><b>3. Обязанности ОРГАНИЗАЦИИ</b></p>
      <p class="public-body__paragraph">3.1. ОРГАНИЗАЦИЯ осуществляет рекламу и распространение Карт. </p>
      <p class="public-body__paragraph">3.2. ОРГАНИЗАЦИЯ публикует информацию о скидках и иных привилегиях,
        предоставленную ПАРТНЕРОМ путем указания в Личном кабинете, на информационном портале МКП РСМ в сети Интернет
        (<a href="/discounts" target="_blank">https://lk.mkp.ruy.ru/discounts</a>) для пользователей в России, <a
          href="https://www.eyca.org/" target="_blank">eyca.org</a> для пользователей в Европе, и в Мобильном
        Приложении.
      </p>
      <p class="public-body__paragraph">3.3. ОРГАНИЗАЦИЯ обязуется своевременно в течение 3 рабочих дней обновлять
        информацию, предоставленную ПАРТНЕРОМ, на информационном портале Проекта в сети Интернет и в Мобильном
        Приложении.</p>
      <p class="public-body__paragraph">3.4. ОРГАНИЗАЦИЯ обязуется оказывать необходимые консультации и обеспечивать
        ПАРТНЕРА инструкциями по обслуживанию Карт, рекламными изображениями Карт и стикерами (наклейками) в необходимых
        количествах.
      </p>
      <p class="public-body__paragraph">3.5. ОРГАНИЗАЦИЯ обязуется модерировать и утверждать с ПАРТНЕРОМ изменения в
        информации, поступающей на сайты <a href="https://mkp.ruy.ru" target="_blank">https://mkp.ruy.ru</a> и <a
          href="https://www.eyca.org/" target="_blank">eyca.org</a> посредством сети Интернет через Мобильное
        Приложение и Личный кабинет в течение 3-х дней с момента загрузки.</p>
      <p class="public-body__paragraph"><b>4. Обязанности ПАРТНЕРА</b></p>
      <p class="public-body__paragraph">4.1. ПАРТНЕР предоставляет Пользователям Карт скидки и иные привилегии при
        приобретении у него товаров (работ, услуг, иного продукта). Перечень и условия предоставления скидок и иных
        привилегий указывается ПАРТНЕРОМ в Личном кабинете.
      </p>
      <p class="public-body__paragraph">4.2. ПАРТНЕР обязан предоставлять скидки в течение всего срока действия
        настоящего Партнерского соглашения Пользователям Карт по номеру Карты на условиях, установленных в соответствии
        с пунктом 4.1 Партнерского соглашения.
      </p>
      <p class="public-body__paragraph">4.3. ПАРТНЕР обязан заполнить предусмотренную формой информацию и
        зарегистрироваться на странице сайта <a href="/partner" target="_blank">https://lk.mkp.ruy.ru/partner</a>.
      </p>
      <p class="public-body__paragraph">4.4. ПАРТНЕР обязуется самостоятельно урегулировать с Пользователями Карт все
        вопросы, связанные с отказами в предоставлении скидок и, в случае обоснованных претензий, предоставить
        Пользователю Карты неполученные скидки.
      </p>
      <p class="public-body__paragraph">4.5. ПАРТНЕР гарантирует размещение стикера (наклейки) с логотипами или
        изображениями Карт и логотипов МКП РСМ и EYCA на видном месте у входа в торговую точку и/или на/у кассовых
        аппаратов (-ах) в точках продажи товаров (работ, услуг) ПАРТНЕРА, а также на официальном сайте.
      </p>
      <p class="public-body__paragraph">4.6. ПАРТНЕР обязан ознакомить работников и специалистов принадлежащих ему точек
        продаж (выполнения работ, оказания услуг и т.п.) с инструкцией по обслуживанию Карт (<a
          href="https://mkp.ruy.ru/files/card-instructions.pdf" target="_blank">Приложение № 1</a>) и
        обеспечить ее выполнение.
      </p>
      <p class="public-body__paragraph">4.7. ПАРТНЕР в случае обнаружения подделки или истечения срока действия Карты
        обязан отказать Пользователю Карты в предоставлении скидки.
      </p>
      <p class="public-body__paragraph">4.8. ПАРТНЕР обязан обеспечить рабочие места кассиров Инструкцией по
        обслуживанию Карт (<a href="https://mkp.ruy.ru/files/card-instructions.pdf" target="_blank">Приложение № 1</a>).
      </p>
      <p class="public-body__paragraph">4.9. ПАРТНЕР, в случае предоставления скидки по Карте или различным дисконтным
        системам или другим программам лояльности, обязан предоставить Пользователю Карты право выбора скидки при
        осуществлении оплаты, исключая предоставление скидки на скидку.
      </p>
      <p class="public-body__paragraph">4.10. ПАРТНЕР не вправе передавать свои аутентификационные данные (логин и
        пароль) третьим лицам для доступа в его Личный кабинет и на сайт ОРГАНИЗАЦИИ.
        Если ПАРТНЕР узнает, что неуполномоченные получили доступ в Личный кабинет ПАРТНЕРА, он обязуется сообщить об
        этом ОРГАНИЗАЦИИ по электронной почте, указанной в настоящем Партнерском соглашении. До момента получения
        ОРГАНИЗАЦИЕЙ такого сообщения все действия, совершенные с использованием сайта ОРГАНИЗАЦИИ, будут считаться
        совершенными ПАРТНЕРОМ.
      </p>
      <p class="public-body__paragraph"><b>5. Права ПАРТНЕРА</b></p>
      <p class="public-body__paragraph">5.1. ПАРТНЕР имеет право получать от ОРГАНИЗАЦИИ дополнительную информацию о
        Проекте МКП РСМ для использования ее в рекламных целях.
      </p>
      <p class="public-body__paragraph">5.2. ПАРТНЕР имеет право предоставлять дополнительные скидки и услуги
        Пользователям Карт (сезонные, бонусные и др.), предоставлять ОРГАНИЗАЦИИ информацию о дополнительных скидках для
        размещения ее на информационном портале Проекта в сети Интернет и в мобильном приложении Проекта, а также в
        рекламных материалах Проекта.
      </p>
      <p class="public-body__paragraph">5.3. ПАРТНЕР имеет право во избежание возникновения между продавцом и
        покупателем (Пользователем Карты) конфликтной ситуации, связанной с механизмом предоставления скидки или
        сомнениями в подлинности Карты, вправе связаться с ОРГАНИЗАЦИЕЙ Согласованным Средством Связи с целью
        подтверждения подлинности Карты и урегулирования вопроса.
      </p>
      <p class="public-body__paragraph">5.4. ПАРТНЕР вправе потребовать документ, удостоверяющий личность Пользователя
        Карты.</p>
      <p class="public-body__paragraph">5.5. ПАРТНЕР самостоятельно ведет учет предоставляемых скидок по Картам.</p>
      <p class="public-body__paragraph">5.6. ПАРТНЕР имеет право менять размеры скидки или предлагаемые бонусы,
        акционные предложения для Пользователей Карт в личном кабинете ПАРТНЕРА.
      </p>
      <p class="public-body__paragraph"><b>6. Права ОРГАНИЗАЦИИ</b></p>
      <p class="public-body__paragraph">6.1. ОРГАНИЗАЦИЯ имеет право гарантировать Пользователям Карт от лица ПАРТНЕРА
        получение скидок и иных привилегий, указанных ПАРТНЕРОМ в Личном кабинете ПАРТНЕРА.
      </p>
      <p class="public-body__paragraph">6.2. ОРГАНИЗАЦИЯ имеет право использовать в информационных целях сведения о
        ПАРТНЕРЕ, включая наименование, характер деятельности, адреса и телефоны, размер и условия предоставляемых
        скидок.
      </p>
      <p class="public-body__paragraph">6.3. ОРГАНИЗАЦИЯ вправе предложить ПАРТНЁРУ разработать и провести совместную
        акцию (мероприятие), направленное на популяризацию Карт и услуг ПАРТНЕРА в молодёжной среде.
      </p>
      <p class="public-body__paragraph">6.4. В случае подтверждения необоснованного отказа ПАРТНЕРА в предоставлении
        Пользователю Карты скидки, установленной в соответствии с настоящим Партнерским соглашением, повторившегося
        более двух раз, ОРГАНИЗАЦИЯ имеет право в одностороннем порядке расторгнуть настоящее Партнерское
        соглашение.
      </p>
      <p class="public-body__paragraph"><b>7. Ответственность сторон</b></p>
      <p class="public-body__paragraph">7.1. ПАРТНЕР несет ответственность перед ОРГАНИЗАЦИЕЙ за достоверность
        предоставленной им информации.</p>
      <p class="public-body__paragraph">7.2 ПАРТНЕР несет всю полноту ответственности по возможным претензиям к
        ОРГАНИЗАЦИИ со стороны Пользователей Карт в случае необоснованного отказа ПАРТНЕРОМ Пользователю Карты в
        предоставлении скидок, указанных в п. 3.1 и п. 3.2 настоящего Партнерского соглашения, в соответствии с
        действующим законодательством РФ.
      </p>
      <p class="public-body__paragraph"><b>8. Форс-мажор</b></p>
      <p class="public-body__paragraph">8.1. Стороны освобождаются от ответственности за полное или частичное
        невыполнение обязательств по настоящему Партнерскому соглашению, если это невыполнение явилось следствием
        запретительных мер государства, местных органов власти или наступления иных обстоятельств непреодолимой силы
        (форс-мажор).
      </p>
      <p class="public-body__paragraph">8.2. При наступлении обстоятельств непреодолимой силы Сторона, для которой
        наступили такие обстоятельства, должна в течение 3 (трех) рабочих дней с момента наступления данных
        обстоятельств известить о них в письменном виде другую Сторону. Извещение должно содержать данные о характере
        обстоятельств, а также, по возможности, оценку их влияния на возможность исполнения Стороной обязательств по
        настоящему Партнерскому соглашению и сроки их исполнения.
      </p>
      <p class="public-body__paragraph">8.3. Если Сторона, подвергшаяся действию непреодолимой силы, не направит или
        несвоевременно направит извещение о наступлении обстоятельств чрезвычайного характера (форс-мажор), то она
        обязана возместить другой стороне убытки, причинённые не извещением или несвоевременным извещением, если только
        действие непреодолимой силы не препятствовало отправке такого сообщения.
      </p>
      <p class="public-body__paragraph"><b>9. Прочие условия</b></p>
      <p class="public-body__paragraph">9.1. Настоящее Партнерское соглашение заключено сроком на 1 (один) год и
        вступает в силу с момента его подписания.
      </p>
      <p class="public-body__paragraph">9.2. Настоящее Партнерское соглашение считается продленным на очередной год,
        если не будет получено уведомление одной из Сторон о желании расторгнуть настоящее Партнерское соглашение не
        менее чем за два месяца до даты расторжения настоящего Партнерского соглашения.
      </p>
      <p class="public-body__paragraph">9.3. Основанием для досрочного расторжения настоящего Партнерского соглашения
        может являться неисполнение Сторонами своих обязательств, предусмотренных настоящим Партнерским соглашением, а
        также иные случаи, предусмотренные действующим законодательством РФ.
      </p>
      <p class="public-body__paragraph">9.4. Споры, возникающие по вопросам исполнения настоящего Партнерского
        соглашения, Стороны обязуются разрешать путем переговоров. В случае отсутствия согласия по их урегулированию,
        указанные споры разрешаются в Арбитражном суде города Москвы в порядке, предусмотренном действующим
        законодательством РФ.
      </p>
      <p class="public-body__paragraph">9.5. ОРГАНИЗАЦИЯ вправе в одностороннем порядке изменять условия Партнерского
        соглашения путем опубликования его в новой редакции на Сайте ОРГАНИЗАЦИИ. Изменения, внесенные в Партнерское
        соглашение, вступают в силу с даты их опубликования на Сайте ОРГАНИЗАЦИИ.
      </p>
      <p class="public-body__paragraph">9.6. ПАРТНЕР вправе в любое время в одностороннем внесудебном порядке отказаться
        от Партнерского соглашения путем направления уведомления об отказе от Партнерского соглашения по электронной
        почте на адрес, указанный в Партнерском соглашении. ОРГАНИЗАЦИЯ вправе в любое время в одностороннем внесудебном
        порядке отказаться от Партнерского соглашения путем удаления Личного кабинета ПАРТНЕРА. ОРГАНИЗАЦИЯ обязуется
        уведомить ПАРТНЕРА об одностороннем отказе от Партнерского соглашения за 3 (три) рабочих дня до даты
        расторжения. Уведомление осуществляется по электронной почте ПАРТНЕРА, указанной им в Личном кабинете на Сайте
        АДМИНИСТРАТОРА.
      </p>
      <p class="public-body__paragraph">9.7. ПАРТНЁР при регистрации должен указать сведения об уполномоченном
        должностном лице, ответственном за связи с ОРГАНИЗАЦИЕЙ (ФИО, должность, номер телефона, адрес e-mail).
      </p>
      <p class="public-body__paragraph">9.8. В случае каких-либо изменений в деятельности Сторон, которые могут повлиять
        на содержание настоящего Партнерского соглашения (смена адреса, банковских реквизитов, организационно-правовой
        формы и др.), Сторона, которую коснулись изменения, обязана проинформировать другую Сторону в 10-дневный срок с
        момента наступления таких изменений.
      </p>
      <p class="public-body__paragraph">9.9. Никакие из положений настоящего Партнерского соглашения не могут быть
        истолкованы в качестве основы для создания товарищества, объединения имущества для совместного ведения
        деятельности или представительства.
      </p>
      <p class="public-body__paragraph">9.10. Во всем остальном, что не предусмотрено настоящим Партнерским соглашением,
        Стороны руководствуются действующим законодательством РФ.
      </p>
      <p class="public-body__paragraph">9.11. Для выполнения условий Партнерского соглашения ПАРТНЕР вправе использовать
        следующие материалы:<br>
        Памятка продавца.<br>
        Инструкция по заполнению формы на сайте <a href="https://www.mkp.ruy.ru/partners" target="_blank">www.mkp.ruy.ru/partners</a>.
      </p>
      <p class="public-body__paragraph"><b>10. Реквизиты и сведения об ОРГАНИЗАЦИИ</b></p>
      <p class="public-body__paragraph">
        Общероссийская общественная организация «Российский Союз Молодежи»<br>
        Адрес: 101990, г. Москва, ул. Маросейка, д. 3/13<br>
        ОГРН 1027739485620 ИНН 7701047627<br>
        <a href="mailto:info@mkp-ruy.ru">info@mkp-ruy.ru</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Offer'
};
</script>

<style scoped>

</style>